@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cs-message--incoming .cs-message__content {
  background-color: var(--incoming-message-bg) !important;
}

.cs-message--outgoing .cs-message__content {
  background-color: var(--outgoing-message-bg) !important;
}

.custom-message-list > div:first-child {
  margin-top: 16px;
  height: calc(100% - 16px);
}

.custom-message .cs-message__avatar {
  width: 24px;
}

.cs-message-input.custom-message-input.completed > div {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
  align-content: center;
}
.cs-message-input.custom-message-input.completed > div > div,
.cs-message-input.custom-message-input.completed > div > div > div,
.cs-message-input.custom-message-input.completed > div > div > div:before {
  background-color: transparent;
}

.cs-message-input.custom-message-input > div,
.cs-message-input.custom-message-input > div > div > div {
  background-color: #fff;
  align-content: center;
}

.cs-message-input.custom-message-input > div > div > div::before {
  color: #48556a !important;
}

.cs-message-input.custom-message-input > div + div {
  margin-left: 8px !important;
  margin-right: 8px !important;
  border-radius: 7px !important;
  background-color: transparent;
  color: transparent;
  z-index: 1;
  width: 73px;
  min-width: 73px;
}

.cs-message-input.custom-message-input > div + div > button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: transparent;
}

.cs-message-input.custom-message-input > div > div {
  line-height: 15px;
  background-color: transparent;
  padding: 5px 0;
}

.cs-message-input.custom-message-input > div:first-of-type::after {
  content: "SEND";
  color: #fff;
  background-color: var(--send-message-button-bg);
  border-radius: 7px;
  padding: 8px 16px;
  cursor: pointer;
  width: 73px;
  right: -81px;
  height: 40px;
  line-height: 25px;
  bottom: 0;
  position: absolute;
  font-size: 16px;
  font-weight: 500;
}

.cs-message-input.custom-message-input.custom-message-input-disabled
  > div:first-of-type::after {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}

.no-line-separator::before,
.no-line-separator::after {
  background-color: transparent !important;
}
